<template>
  <v-container>
    <div>
      <v-data-table
        v-if="!showDepartmentTable"
        :loading="loading"
        loading-text="Loading... Please wait"
        :headers="headers"
        :items="items"
        :search="search"
        hide-default-footer
        @page-count="pageCount = $event"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        class="depar-table"
      >
        <template v-slot:item.actions="{ item }">
          <div class="d-flex justify-center" style="margin-top: -4px">
            <v-btn
              color="#4FB14E"
              text
              class="text-capitalize"
              style="font-size: 16px"
              @click="checkDepartmentEdit(item)"
            >
              <v-icon>mdi-square-edit-outline</v-icon>
              {{ $t("edit") }}
            </v-btn>
            <v-btn
              color="error"
              text
              class="text-capitalize"
              style="font-size: 16px"
              @click="checkDepartmentDelete(item)"
            >
              <v-icon>mdi-delete-outline</v-icon>
              {{ $t("delete") }}
            </v-btn>
          </div>
        </template>
      </v-data-table>

      <v-row
        no-gutters
        v-if="!showDepartmentTable"
        class="d-flex justify-space-between mt-2"
      >
        <v-col auto class="d-flex align-center">
          <div class="d-flex text-left">
            <v-pagination
              circle
              class="pagination"
              v-model="page"
              :length="pageCount"
            ></v-pagination>
          </div>
        </v-col>

        <v-col cols="12" lg="2" md="3" sm="4" class="d-flex align-center justify-end">
          <p class="mt-3 pr-4">{{ $t("Show") }}</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                width="70"
                v-bind="attrs"
                v-on="on"
                class="text-capitalize mt-1"
                outlined
              >
                {{ itemsPerPage }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in paginationCount"
                :key="index"
                @click="itemsPerPage = item.title"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>

      <MemberList v-if="showDepartmentTable" />

      <Add
        :show="addDepartmentDialog"
        @close="$store.commit('closeAddDepartmentDialog')"
        @confirm="addConfirm()"
      />

      <Edit
        :show="editDepartmentDialog"
        @close="
          $store.commit('closeEditDepartmentDialog'),
            $store.commit('closeStartingDepartmentActive'),
            $store.commit('editDepartment', false)
        "
        @stopStartingActive="startingActive = false"
        @confirm="editConfirm()"
      />
      <!-- $store.commit('clearSelected'), -->
      <Delete
        :show="deleteDialog"
        :item="deleteData"
        @close="deleteDialog = false"
        @confirm="deleteDepartment()"
      />

      <SuccessDialog
        :show="show"
        :text="$t('deletedepartment')"
        :title="$t('success')"
        @close="show = false"
      />
      <SuccessDialog
        :show="addshow"
        :text="$t('adddepartment')"
        :title="$t('success')"
        @close="addshow = false"
      />
      <SuccessDialog
        :show="editshow"
        :text="$t('editdepartmentsuccess')"
        :title="$t('success')"
        @close="editshow = false"
      />
      <PermissionDialog
        :show="permissionDialog"
        title="Error"
        :text="permissionMessage"
        @close="permissionDialog = false"
      />
    </div>
  </v-container>
</template>

<script>
import Add from "./Add.vue";
import Edit from "./Edit.vue";
import Delete from "./Delete.vue";
import MemberList from "./MemberList.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Add,
    Edit,
    Delete,
    MemberList,
  },
  props: {
    search: String,
  },
  data: () => ({
    permissionDialog: false,
    permissionMessage: "",
    deleteDialog: false,
    items: [],
    //search: null,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    paginationCount: [
      { title: 5 },
      { title: 10 },
      { title: 20 },
      { title: 30 },
      { title: 40 },
    ],
    loading: true,
    deleteData: {},
    show: false,
    addshow: false,
    editshow: false,
  }),
  computed: {
    ...mapGetters([
      "addDepartmentDialog",
      "editDepartmentDialog",
      "showDepartmentTable",
    ]),
    headers() {
      return [
        {
          text: "#",
          value: "no",
          class: "tableColor",
          align: "left",
        },
        {
          text: this.$t("departmentid"),
          value: "uGroupID",
          align: "left",
          class: "tableColor",
        },
        {
          text: this.$t("departmentnamesm"),
          align: "left",
          value: "groupName",
          class: "tableColor",
        },
        {
          text: this.$t("memberlistsm"),
          align: "left",
          value: "memberList",
          class: "tableColor",
        },
        {
          text: this.$t("role"),
          align: "left",
          value: "roleName",
          class: "tableColor",
        },
        {
          text: this.$t("Manage"),
          align: "center",
          value: "actions",
          class: "tableColor",
        },
      ];
    },
  },
  mounted() {
    this.getGroup();
  },
  methods: {
    async checkDepartmentDelete(item) {
      let self = this;
      let checkPermission = self.check_Permissions("usermanagement");
      if (checkPermission) {
        self.deleteDialog = true;
        self.deleteData = item;
      } else {
        self.permissionDialog = true;
        self.permissionMessage =
          "You don't have permission to delete Department.";
      }
    },
    async checkDepartmentEdit(item) {
      let self = this;
      let checkPermission = self.check_Permissions("usermanagement");
      if (checkPermission) {
        self.$store.commit("openEditDepartmentDialog");
        self.$store.commit("saveEditDepartmentData", item);
        self.$store.commit("openStartingDepartmentActive");
      } else {
        self.permissionDialog = true;
        self.permissionMessage =
          "You don't have permission to edit Department.";
      }
    },
    async checkDepartmentAdd() {
      let self = this;
      let checkPermission = self.check_Permissions("usermanagement");
      if (checkPermission) {
        self.$store.commit("openAddDepartmentDialog");
      } else {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to add Department.";
      }
    },
    async getGroup() {
      let self = this;
      self.loading = true;
      let checkPermission = true;
      if (checkPermission) {
        const res = await self.$axios.get(
          `${self.web_url}Group/GetGroupALL?id=` +
            localStorage.getItem("companyID")
        );
        self.items = res.data.data.map((v, i) => ({
          ...v,
          no: i + 1,
        }));

        let itemCount = self.items ? self.items.length : 0;
        self.$emit("itemsCount", itemCount);
      } else {
        self.permissionDialog = true;
        self.permissionMessage =
          "You don't have permission to see Department Data.";
      }
      self.loading = false;
    },

    async deleteDepartment() {
      const res = await this.$axios.post(
        `${this.web_url}Group/DeleteGroup?id=` + this.deleteData.groupID
      );
      if (res.status == 200) {
        this.deleteDialog = false;
        this.show = true;
        this.getGroup();
      }
    },

    addConfirm() {
      this.$store.commit("closeAddDepartmentDialog");
      this.addshow = true;
      this.getGroup();
      this.$store.commit("clearSelected");
    },

    editConfirm() {
      this.$store.commit("closeEditDepartmentDialog");
      this.$store.commit("closeStartingDepartmentActive");
      this.editshow = true;
      this.getGroup();
      this.$store.commit("clearSelected");
      this.$store.commit("clearEditDepartmentData");
    },
  },
};
</script>
<style scoped>
::v-deep .v-label {
  font-size: 20px !important;
}
::v-deep .faq-table tr:nth-child(even) {
  background: #e0e0e0;
}
::v-deep .v-text-field__slot {
  font-size: 16px !important;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #47484b !important;
  background-color: #dbdbda !important;
  opacity: 1;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
  vertical-align: center;
}
::v-deep .v-dialog {
  border-radius: 35px !important;
}
::v-deep .primary {
  color: #000000 !important;
  background-color: #a6cc39 !important;
  opacity: 1;
}
::v-deep .pagination .primary {
  background-color: #e8e9ec !important;
  color: #4d4f5c !important;
  opacity: 1;
}
</style>

<style>
.depar-table thead th:first-child {
  border-radius: 8px 0 0 8px !important;
}
.depar-table thead th:last-child {
  border-radius: 0 8px 8px 0 !important;
}
</style>
