<template>
  <div>
    <v-dialog v-model="addDialog" width="800" scrollable persistent>
      <v-card>
        <v-card-title style="font-size: 20px; height: 70px">
          <p>
            <v-icon class="ma-1" style="font-size: 28px; color: #424242"
              >mdi-account-plus-outline</v-icon
            >
            {{ $t("newuser") }}
          </p>

          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="Cancel()"
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
              margin-bottom: 16px;
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 700px">
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col>
                <div class="d-flex">
                  <p class="pt-5" style="font-size: 20px; color: #a6cc39">
                    {{ $t("userinformation") }}
                  </p>

                  <div class="onoffswitch mt-4 ml-5">
                    <input
                      type="checkbox"
                      name="onoffswitch"
                      class="onoffswitch-checkbox"
                      id="myonoffswitch"
                      v-model="user.status"
                    />

                    <label for="myonoffswitch" class="onoffswitch-label">
                      <div class="onoffswitch-inner"></div>
                      <div class="onoffswitch-switch"></div>
                    </label>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-divider class="secondary"></v-divider>
            <v-row class="mt-3">
              <v-col cols="12" xs="12" sm="12" md="6">
                <label for="employeeId" class="font-style">
                  {{ $t("employeeId") }}
                  <span style="color: red">*</span>
                </label>
                <v-text-field
                  :rules="userRules"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="user.userId"
                  class="mt-2"
                ></v-text-field>
                <span v-if="isEmpidExist" style="color: red" class="text"
                  >Employee ID already exist</span
                >
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6">
                <label for="prefix" class="font-style">{{
                  $t("prefix")
                }}</label>
                <v-select
                  :items="prefixItems"
                  item-value="name"
                  item-text="name"
                  v-model="user.prefix"
                  outlined
                  dense
                  hide-details="auto"
                  class="mt-2"
                  label="Please Select"
                ></v-select>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6">
                <label for="firstname" class="font-style">
                  {{ $t("firstname") }}
                  <span style="color: red">*</span>
                </label>
                <v-text-field
                  :rules="firstNameRules"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="user.firstName"
                  class="mt-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6">
                <label for="lastname" class="font-style">
                  {{ $t("lastname") }}
                  <span style="color: red">*</span>
                </label>
                <v-text-field
                  :rules="lastNameRules"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="user.lastName"
                  class="mt-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6">
                <label for="email" class="font-style">
                  {{ $t("email") }}
                  <span style="color: red">*</span>
                </label>
                <v-text-field
                  :rules="emailRules"
                  :error-messages="mailMessage"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="user.email"
                  class="mt-2"
                ></v-text-field>
              </v-col>
              <v-progress-circular
                v-if="loading"
                indeterminate
                class="loading-circle"
                color="#A6CC39"
              ></v-progress-circular>
              <v-col cols="12" xs="12" sm="12" md="6">
                <label for="telephone" class="font-style">
                  {{ $t("telephone") }}
                  <span style="color: red">*</span>
                </label>
                <v-text-field
                  :rules="phoneNumberRules"
                  v-mask="'###########'"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="user.telephone"
                  class="mt-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6">
                <label for="lineId" class="font-style">
                  {{ $t("lineId") }}
                </label>
                <v-text-field
                  outlined
                  dense
                  hide-details="auto"
                  v-model="user.lineId"
                  class="mt-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6">
                <label for="group" class="font-style">{{ $t("group") }}</label>
                <v-select
                  :items="editdeptList"
                  item-text="groupName"
                  item-value="groupID"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="user.group"
                  label="Please Select"
                  class="mt-2"
                  clearable
                  multiple
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @click="getSelectAllGroup"
                      style="border-bottom: 1px solid #00000026"
                    >
                      <v-list-item-action>
                        <v-icon
                          :color="user.group.length > 0 ? '#a6cc39' : ''"
                          >{{ icon_dpt }}</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <v-chip
                      v-if="index === 0"
                      style="background: rgba(121, 190, 67, 0.5)"
                    >
                      <span>{{ item.groupName }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption"
                      >(+{{ user.group.length - 1 }} others)</span
                    >
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6">
                <label for="departmentl" class="font-style">
                  {{ $t("departmentl") }}
                </label>

                <v-select
                  :items="filteredNames"
                  item-text="departmentName"
                  item-value="departmentId"
                  outlined
                  dense
                  hide-details="auto"
                  label="Please Select"
                  class="mt-2"
                  clearable
                >
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-text-field
                          v-model="search"
                          :placeholder="$t('search')"
                          autocomplete="off"
                          hide-details
                          class="searchinlist"
                          append-icon="mdi-magnify"
                        ></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item
                      class="custom-border"
                      @change="selectItem(item.departmentId)"
                    >
                      <v-list-item-content style="border-radious=10px">
                        <v-list-item-title>{{
                          item.departmentName
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="divider"></v-divider>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <v-chip
                      v-if="index === 0"
                      style="background: rgba(121, 190, 67, 0.5)"
                    >
                      <span>{{ item.departmentName }}</span>
                    </v-chip>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6">
                <label for="role" class="font-style">
                  {{ $t("role") }}
                  <span style="color: red">*</span>
                </label>
                <v-select
                  :rules="roleNameRules"
                  :items="levelList"
                  item-text="roleName"
                  item-value="userRoleID"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="user.level"
                  label="Please Select"
                  class="mt-2"
                  clearable
                  multiple
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @click="getSelectAllRole"
                      style="border-bottom: 1px solid #00000026"
                    >
                      <v-list-item-action>
                        <v-icon
                          :color="user.level.length > 0 ? '#a6cc39' : ''"
                          >{{ icon }}</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <v-chip
                      v-if="index === 0"
                      style="background: rgba(121, 190, 67, 0.5)"
                    >
                      <span>{{ item.roleName }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption"
                      >(+{{ user.level.length - 1 }} others)</span
                    >
                  </template>
                </v-select>
              </v-col>
              <v-col cols="6">
                <label for="gender" class="font-style">{{
                  $t("gender")
                }}</label>
                <v-radio-group row v-model="user.gender">
                  <v-radio
                    :label="$t('male')"
                    value="male"
                    color="secondary"
                  ></v-radio>
                  <v-radio
                    :label="$t('female')"
                    value="female"
                    color="secondary"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="6">
                <label for="sendnotification" class="font-style">{{
                  $t("sendnotification")
                }}</label>
                <v-row  
                >
                <v-col  cols="2">
                <v-checkbox
                  v-model="user.EmailCheck"
                  :label="$t('email')"
                  color="secondary"
                  hide-details
                  @change="handleCheckboxChange"
                ></v-checkbox>
                </v-col>
                <template>
                      <v-col class="d-flex justify-center align-center">
                        <v-row class="d-flex justify-center align-center">
                          <v-radio-group model="user.SelectedRadio" class="radio-group" :disable="!user.EmailCheck">
                            <v-row rows="12" class="radiobutton mt-3">
                              <div class="radio-wrapper">
                             
                             <div v-if="!user.EmailCheck" class="radio-placeholder"></div>
                            
                             <v-radio
                               v-else
                               value="thai"
                               class="radio-button"
                             ></v-radio>
                           </div>
                           <v-img
                              src="@/assets/thailandflag.svg"
                              width="35"
                              height="22"
                              class="ml-2"
                            ></v-img>
                            <div class="radio-wrapper ml-2">
                             
                             <div v-if="!user.EmailCheck" class="radio-placeholder"> </div>
                            <v-radio
                            v-else
                            class=" radio-button"
                             value="eng"
                            
                              >
                            </v-radio>
                            </div>
                            <v-img
                              src="@/assets/ukflag.svg"
                              width="35"
                              height="22"
                              class="ml-2"
                            ></v-img>
                            </v-row>
                            
                          </v-radio-group>
                         
                        </v-row>
                      </v-col>
                    </template>
              </v-row>
              </v-col>

              <v-col cols="12">
                <p style="font-size: 20px; color: #a6cc39">
                  {{ $t("logincred") }}
                </p>
                <v-divider class="secondary"></v-divider>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6">
                <label for="account" class="font-style">
                  {{ $t("account") }}
                  <span style="color: red">*</span>
                </label>
                <v-text-field
                  :rules="accountRules"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="user.username"
                  class="mt-2"
                ></v-text-field>
                <span v-if="isExist" style="color: red" class="text"
                  >This username is already taken</span
                >
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6">
                <label for="password" class="font-style">
                  {{ $t("password") }}
                  <span style="color: red">*</span>
                </label>
                <v-text-field
                  :rules="passwordRules"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                  v-model="user.password"
                  placeholder="******"
                  outlined
                  dense
                  hide-details="auto"
                  class="mt-2"
                  :append-icon="showPassword ? 'mdi-lock-open' : 'mdi-lock'"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mt-5 mb-5">
          <v-spacer></v-spacer>
          <v-btn
            text
            outlined
            width="100"
            class="text-capitalize mr-3"
            @click="Cancel()"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            class="text-capitalize"
            width="100"
            color="secondary"
            @click="submit()"
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
export default {
  directives: { mask },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    // ActiveEmployeeCount:Number
  },
  computed: {
    ...mapGetters(["levelList", "editdeptList"]),
    //for group/department
    selectAllGroup() {
      return this.user.group.length === this.editdeptList.length;
    },
    filteredNames() {
      return this.Departmentitems.filter((x) =>
        x.departmentName.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    icon_dpt() {
      if (this.selectAllGroup) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
    //for role
    selectAllRole() {
      return this.user.level.length === this.levelList.length;
    },
    icon() {
      if (this.selectAllRole) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },

    addDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
    userRules() {
      return [(v) => !!v || "Employee ID is required"];
    },
    prefixRules() {
      return [(v) => !!v || "Prefix is required"];
    },
    firstNameRules() {
      return [(v) => !!v || "First name is required"];
    },
    lastNameRules() {
      return [(v) => !!v || "last name is required"];
    },
    emailRules() {
      return [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ];
    },
    phoneNumberRules() {
      return [
        (v) => !!v || "Phone number is required",
        (v) =>
          (v && v.length >= 10) ||
          "Phone Number must be greather than 10 characters",
        (v) =>
          (v && v.length <= 11) ||
          "Phone Number must be less than 11 characters",
      ];
    },
    roleNameRules() {
      return [(v) => v.length > 0 || "Please select a role."];
    },
    DepNameRules() {
      return [(v) => v.length > 0 || "Please select a department."];
    },
    genderRules() {
      return [(v) => !!v || "Gender is required"];
    },
    accountRules() {
      return [(v) => !!v || "Username is required"];
    },
    passwordRules() {
      return [(v) => !!v || "Password is required"];
    },
  },
  data: () => ({
    selectedRadio: null,
    valid: false,
    characters: "a-z-A-Z",
    showPassword: false,
    prefixItems: [
      { id: 1, name: "Mr." },
      { id: 2, name: "Ms." },
      { id: 3, name: "Mrs." },
      { id: 4, name: "Miss." },
    ],
    CouresSelect: null,
    departments: [],
    levels: [],
    search: "",
    mailMessage: "",
    user: {
      status: true,
      userId: "",
      prefix: "",
      firstName: "",
      lastName: "",
      username: "",
      telephone: "",
      lineId: "",
      // department: [],
      // level: null,
      group: [],
      level: [],
      gender: "",
      password: "",
      EmailCheck: false,
      SelectedRadio: "",
    },
    loading: false,
    isExist: false,
    isEmpidExist: false,
    PricingData: [],
    Departmentitems: [],
  }),
  mounted() {
    this.generatePassword();
    this.getGroupAll();
    this.getDepartmentList();
    this.getLevel();
    this.getPricingData();
    this.getCountForEmployee();
  },
  methods: {
    handleCheckboxChange() {
      if (this.user.EmailCheck) {
      
        this.user.SelectedRadio = 'thai';
      } else {
      
        this.user.SelectedRadio = null;
      }
    },
    getSelectAllGroup() {
      if (this.user.group.length == 0) {
        this.user.group = this.editdeptList.map(({ groupID }) => groupID);
      } else {
        this.user.group = [];
      }
    },

    getSelectAllDepartment() {
      if (this.user.level.length == 0) {
        this.user.level = this.levelList.map(
          ({ departmentId }) => departmentId
        );
      } else {
        this.user.level = [];
      }
    },
    selectItem(itemId) {
      this.selectedItem = itemId;
    },

    getSelectAllRole() {
      if (this.user.level.length == 0) {
        this.user.level = this.levelList.map(({ userRoleID }) => userRoleID);
      } else {
        this.user.level = [];
      }
    },

    async getPricingData() {
      const res = await this.$axios.get(
        `${this.web_urlV4}Package/GetPackageOfCompanyCustomer?CompanyId=` +
          localStorage.getItem("companyID")
      );
      this.PricingData = res.data.data;
    },
    async getCountForEmployee() {
      const res = await this.$axios.get(
        `${this.web_url}Company/GetCompanyActiveUserMP3MP4Count?id=` +
          localStorage.getItem("companyID")
      );
      this.ActiveEmployeeCount = res.data.data[0].activeEmployee;
    },
    generatePassword() {
      let charactersArray = this.characters.split(",");
      let CharacterSet = "";
      let password = "";

      if (charactersArray.indexOf("a-z-A-Z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }

      for (let i = 0; i < 10; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      this.user.password = password;
    },

    async getDepartmentList() {
      const res = await this.$axios.get(
        `${this.web_url}Department/GetDepartmentList?id=` +
          localStorage.getItem("companyID")
      );
      this.Departmentitems = res.data.data;
      this.$store.commit("savedeparmentList", res.data.data);
    },
    async getGroupAll() {
      const res = await this.$axios.get(
        `${this.web_url}Group/GetGroupALL?id=` +
          localStorage.getItem("companyID")
      );
      //this.$store.commit("savedeparmentList", res.data.data);
      this.$store.commit("editeparmentList", res.data.data);
    },

    async getLevel() {
      const res = await this.$axios.post(
        `${this.web_url}RoleAndPermission/GetRoleForUserAndGroup?companyid=` +
          localStorage.getItem("companyID")
      );
      this.$store.commit("saveroleList", res.data.data);
    },
    async ChkUsername() {
      const res = await this.$axios.post(
        `${this.web_url}Company/CheckUser?username=` +
          this.user.username +
          "&abbr=" +
          localStorage.getItem("Abbreviation")
      );
      if (res.data.data) {
        this.isExist = false;
      } else {
        this.isExist = true;
      }
    },
    async ChkEmpId() {
      const res = await this.$axios.post(
        `${this.web_url}Company/CheckEmployeeID?empid=` +
          this.user.userId +
          "&abbr=" +
          localStorage.getItem("Abbreviation")
      );
      if (res.data.data) {
        this.isEmpidExist = false;
      } else {
        this.isEmpidExist = true;
      }
    },

    async emailExists() {
      const res = await this.$axios.post(`${this.web_url}User/CheckUser`, {
        email: this.user.email,
      });
      if (res.data.data) {
        this.mailMessage = res.data.message;
      } else {
        this.mailMessage = "";
      }
    },
    Cancel() {
      this.$refs.form.resetValidation();
      this.user = {
        status: true,
        lock: false,
        userId: "",
        prefix: "",
        firstName: "",
        lastName: "",
        email: "",
        lineId: "",
        telephone: "",
        group: [],
        level: [],
        gender: "",
        password: "",
        EmailCheck: false,
        SelectedRadio: "",
      };
      this.isEmpidExist = false;
      this.isExist = false;
      this.addDialog = false;
    },
    async submit() {
      let self = this;
      if (this.$refs.form.validate()) {
        await this.ChkUsername();
        await this.ChkEmpId();
      }
      if (this.valid && this.isExist === false && this.isEmpidExist === false) {
        if (self.user.status === true) {
          if (self.ActiveEmployeeCount < self.PricingData.activeEmpId) {
            self.AddFunction();
          } else {
            alert(
              "The active employees are reach limit amount. Contact Seedkm administrator to enhance usage"
            );
          }
        } else {
          self.AddFunction();
        }
      }
    },
    async AddFunction() {
      this.loading = true;
      let empGroup = [];
      let empRole = [];
      for (const element of this.user.group) {
        empGroup.push({
          createBy: localStorage.getItem("userName"),
          updateBy: "",
          employeeID: 0,
          groupID: element,
        });
      }
      for (const element of this.user.level) {
        empRole.push({
          createBy: localStorage.getItem("userName"),
          updateBy: "",
          employeeID: 0,
          roleID: element,
        });
      }
      const emp_data = {
        createBy: "",
        createDate: "",
        companyId: localStorage.getItem("companyID"),
        departmentId: this.selectedItem,
        username: this.user.username,
        password: this.user.password,
        agentID: 0,
        roleId: 0,

        idcard: 0,
        prefix: this.user.prefix,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        mobile: this.user.telephone,
        lineID: this.user.lineId,
        department: "",
        levels: "",
        email: this.user.email,
        pathImage: "",
        insertByUserId: "",
        salarid: 0,
        startWorkDate: "",
        supervisorID: 0,
        positions: "",
        empID: this.user.userId,
        lock: this.user.status,
        employeeGroup: empGroup,
        employeeRole: empRole,
        gender: this.user.gender,
        isEnable: true,
        emailCheck: this.user.EmailCheck,
        emailLanguage: this.user.SelectedRadio,

      };
      console.log("emaildata",emp_data);
      const res = await this.$axios.post(
        `${this.web_url}EmployeeEXT/AddEmployeeExt`,
        emp_data
      );
      this.user = {
        status: true,
        lock: false,
        userId: "",
        prefix: "",
        firstName: "",
        lastName: "",
        email: "",
        telephone: "",
        lineId: "",
        department: [],
        group: [],
        level: [],
        gender: "",
        password: "",
        EmailCheck: true,
        SelectedRadio: "",
      };
      this.chkMail = true;
      this.isExist = false;
      this.isEmpidExist = false;
      if (res.data.status == 0) {
        this.$emit("confirm");
        this.getPricingData();
        this.getCountForEmployee();
      }
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.radio-group {
  display: flex;
  align-items: center;
}

.radio-wrapper {
  position: relative;
  width: 22px;
  height: 22px;
}
.radiobutton{
  margin-top: -10px;
}
.radio-placeholder,
.radio-button {
  position: absolute;
  top: 2px;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.radio-placeholder {
  background-color:#424242;
  opacity: 0.1;
  pointer-events: none; /* Prevent interaction */
}

.radio-button {
  /* Additional styles for v-radio if needed */
}
.loading-circle {
  position: fixed;
  z-index: 5;
  right: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}
.onoffswitch {
  position: relative;
  width: 73px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
}

.onoffswitch-inner {
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  float: left;
  width: 50%;
  height: 28px;
  padding: 0;
  line-height: 30px;
  font-size: 15px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: "ON";
  padding-left: 11px;
  background-color: #a6cc39;
  color: #ffffff;
}

.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 11px;
  color: #ffffff;
  background-color: #707070;
  opacity: 1;
  text-align: right;
}

.onoffswitch-switch {
  width: 26px;
  height: 26px;
  margin: 1px;
  background: #ffffff;
  border-radius: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 44px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

.editonoffswitch {
  position: relative;
  width: 78px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.editonoffswitch-checkbox {
  display: none;
}

.editonoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #999999;
  border-radius: 20px;
}

.editonoffswitch-inner {
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

.editonoffswitch-inner:before,
.editonoffswitch-inner:after {
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 15px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.editonoffswitch-inner:before {
  content: "ON";
  padding-left: 11px;
  background-color: #a6cc39;
  color: #ffffff;
}

.editonoffswitch-inner:after {
  content: "OFF";
  padding-right: 11px;
  color: #ffffff;
  background-color: #707070;
  opacity: 1;
  text-align: right;
}

.editonoffswitch-switch {
  width: 26px;
  margin: 2px;
  background: #ffffff;
  border: 2px solid #999999;
  border-radius: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 44px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}

.editonoffswitch-checkbox:checked
  + .editonoffswitch-label
  .editonoffswitch-inner {
  margin-left: 0;
}

.editonoffswitch-checkbox:checked
  + .editonoffswitch-label
  .editonoffswitch-switch {
  right: 0px;
}
/* end switch */
::v-deep .v-dialog {
  border-radius: 35px !important;
}
.font-style {
  font-size: 16px;
  color: #4d4f5c;
}
::v-deep .v-label {
  font-size: 16px !important;
  color: #4d4f5c !important;
}
::v-deep .v-input__slot {
  font-size: 16px !important;
  border-radius: 10px !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important ;
}

::v-deep .v-btn__content {
  font-size: 16px;
}
.custom-border {
  border-radius: 30px !important;
}
</style>
